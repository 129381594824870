<template>
    <div class="home-box">
      <div>
        <div class="el-dialog-header-box">
          <div class="dialog-title-block"></div>
          <span class="dialog-title-text">商家管理</span>
        </div>
        <el-divider />
      </div>

      <!-- 查询区域 -->
      <div class="top-query">
        <div class="top-query-left">
          <div v-if="!queryType" class="demo-input-suffix">
            商家状态：
            <el-select v-model="query.isshenhe" filterable clearable placeholder="请选择商家状态">
              <el-option
                v-for="item in isshenheOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="demo-input-suffix">
            商家名称：
            <el-select v-model="query.storeid" filterable clearable placeholder="请输入商家名称">
              <el-option
                v-for="item in storeOptions"
                :key="item.id"
                :label="item.storename"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="demo-input-suffix">
            商家Id：
            <el-input
              placeholder="请输入商家Id"
              prefix-icon="el-icon-search"
              v-model="query.storeid"
              clearable
            ></el-input>
          </div>
          <div class="demo-input-suffix">
            手机号：
            <el-input
              placeholder="请输入手机号"
              prefix-icon="el-icon-search"
              type="number"
              class="inputFund"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="query.mobile"
              clearable
            ></el-input>
          </div>
          <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          <el-button @click="add" type="success" icon="el-icon-plus">新增</el-button>
        </div>
  
        <div class="top-query-right"></div>
      </div>
  
      <!-- table区域-begin -->
      <div class="table-box">
        <el-table :data="tableData.data" stripe style="width: 100%" v-loading="loading">
          <el-table-column prop="id" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="name" label="名称" min-width="280">
            <template slot-scope="scope">
                <span>{{ scope.row.storename }}</span><br>
                <span>商家级别：{{ scope.row.total_balance }}</span><br>
                <span>account_id：{{ scope.row.account_id }}</span><br>
                <span>代理ID：{{ scope.row.agentid }}</span><br>
                <span v-if="queryType==3">拒绝原因：
                  <el-tooltip effect="dark" :content="scope.row.shenhe_msg" placement="bottom-start">
                    <el-tag type="danger">{{ scope.row.shenhe_msg }}</el-tag>
                  </el-tooltip>
                </span>
            </template>
          </el-table-column>
          <el-table-column prop="withdraw_code" label="提现账号" min-width="240">
            <template slot-scope="scope">
              <span style="font-size: 17px;">银行收款记账户信息</span><br>
              <el-tag type="warning" style="height:auto;color: #000;">
                <span style="white-space: normal;height:auto;">{{ scope.row.bank_info }}</span><br>
                <span>提现账号：{{ scope.row.withdraw_code }}</span><br>
                <span>系统账号：{{ scope.row.login_code }}</span><br>
                <span>系统密码：{{ scope.row.password }}</span><br>
              </el-tag>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="logo" label="Logo" min-width="120">
            <template slot-scope="scope">
              <el-image style="width: 100px; height: 100px" :src="scope.row.logo" fit="fit"></el-image>
            </template>
          </el-table-column> -->
          <el-table-column prop="mobile" label="商户联系人" min-width="120">
          </el-table-column>
          <el-table-column prop="name" label="提现分账" min-width="220">
            <template slot-scope="scope">
              <span>执行日期：{{ scope.row.withdraw_date }}</span><br>
              <span>执行时间：{{ scope.row.withdraw_time }}</span><br>
              <span>执行状态：{{ scope.row.withdraw_code }}</span><br>
              <span>备注：{{ scope.row.withdraw_msg }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="agentid" label="业务员ID" min-width="120">
          </el-table-column>
          <el-table-column prop="status" label="状态" min-width="80">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status == 1 ? '' : 'danger'">{{ scope.row.status == 1 ? '显示' : '禁用' }}</el-tag>
            </template>>
          </el-table-column>
          <el-table-column prop="createdate" label="添加时间" min-width="120">
          </el-table-column>
          <!-- <el-table-column prop="createdate" label="所属业务员" min-width="120">
          </el-table-column> -->
          <el-table-column fixed="right" header-align="center" align="center" label="操作" min-width="220">
            <template slot-scope="scope">
              <el-button type="primary" plain size="small" @click="handleEdit(scope.row.id)">编辑</el-button>
              <el-popconfirm v-if="scope.row.agentid" title="确定解绑业务员吗" @confirm="unbindSalesman(scope.row.id)">
                <el-button  slot="reference" type="danger" plain size="small">解绑业务员</el-button>
              </el-popconfirm>
              <el-button v-else slot="reference" type="success" plain size="small" @click="bindAgent(scope.row.id)">绑定业务员</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- table区域-end -->
  
      <!-- 分页区域-begin -->
      <div>
        <el-pagination
          class="pagination"
          background layout="prev, pager, next"
          :total="tableData.total"
          :page-size="tableData.per_page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <!-- 分页区域-end -->

      <!-- dialog -->
      <el-dialog :title="title" :visible.sync="dialogFormVisible" @close="dialogFormClose('form')">
        <el-form v-if="dialogType == 'add' || dialogType == 'edit'" :model="form" :rules="rules" :label-width="formLabelWidth" ref="form">
          <el-form-item label="门店名称" prop="storename">
            <el-input v-model="form.storename" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item label="抖音商户ID" prop="account_id">
            <el-input v-model="form.account_id" style="width: 90%" :disabled="dialogType == 'edit' && queryType==2"></el-input>
          </el-form-item>
          <el-form-item label="LOGO" prop="logo">
            <el-upload
              :disabled="dialogType == 'edit' && queryType==2"
              class="upload-demo"
              action="#"
              ref="upload"
              accept="image/*"
              :on-preview="handlePreview"
              :on-remove="function(file, fileList) {return handleRemove(file, fileList,'logo')}"
              multiple
              list-type="picture-card"
              :limit="1"
              :file-list="fileList.logo"
              :http-request="function(file) {return handleFileUpload(file,'logo')}"
              >
              <i class="el-icon-upload"></i>
              <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2M</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="商户联系人" prop="mobile">
            <el-input v-model="form.mobile" placeholder="用于接受短信" autocomplete="off" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item label="地区" prop="area">
            <el-cascader v-if="dialogFormVisible" :options="options" v-model="form.area" clearable placeholder="地区" @change="areaChange" :disabled="dialogType == 'edit' && queryType==2"></el-cascader>
          </el-form-item>
          <el-form-item label="法人手机号" prop="faren_mobile">
            <el-input v-model="form.faren_mobile" placeholder="法人手机号" autocomplete="off" style="width: 90%"></el-input>
          </el-form-item>
          <el-form-item label="提现银行卡号" prop="withdraw_code">
            <el-input disabled v-model="form.withdraw_code" placeholder="提现银行卡号" style="width: 90%"></el-input>
            <span style="display: block;color: #ff1d1d;height: 26px;">请联系服务商{{dialogType=='add'?'添加':'修改'}}</span>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="form.status" style="width: 90%" :disabled="dialogType == 'edit' && queryType==2">
              <el-radio :label="1">显示</el-radio>
              <el-radio :label="2">禁用</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="商家原提现银行卡开户行" prop="tx_code">
            <el-input v-model="form.tx_code" placeholder="商家原提现银行卡开户行" style="width: 90%" :disabled="dialogType == 'edit' && queryType==2"></el-input>
          </el-form-item>
          <el-form-item label="商家抽佣比例" prop="rate">
            <el-input v-model="form.rate" placeholder="商家抽佣比例" :disabled="dialogType == 'edit'||queryType==2" style="width: 90%">
              <template slot="append">%</template>
            </el-input>
            <span v-if="dialogType == 'edit'" style="display: block;color: #ff1d1d;height: 26px;">修改商家抽佣比例请联系平台客服</span>
          </el-form-item>
          <el-form-item label="合伙人佣金比例" prop="agent_rate">
            <el-input v-model="form.agent_rate" placeholder="合伙人佣金比例" style="width: 90%">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item label="推广员佣金比例" prop="tgy_rate">
            <el-input v-model="form.tgy_rate" placeholder="推广员佣金比例" style="width: 90%">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item label="营业执照名称" prop="yyzz_name">
            <el-input v-model="form.yyzz_name" placeholder="请输入营业执照名称" style="width: 90%" :disabled="dialogType == 'edit' && queryType==2"></el-input>
          </el-form-item>
          <el-form-item label="商家资质：" prop="card_url">
            <el-upload
              :disabled="dialogType == 'edit' && queryType==2"
              class="upload-demo"
              action="#"
              ref="upload"
              accept="image/*"
              :on-preview="handlePreview"
              :on-remove="(file, fileList)=>{return handleRemove(file, fileList,'card_url')}"
              multiple
              list-type="picture-card"
              :file-list="fileList.card_url"
              :http-request="(file)=>{return handleFileUpload(file,'card_url')}"
              >
              <i class="el-icon-upload"></i>
              <div slot="tip" class="el-upload__tip">营业执照、身份证正反面、法人银行卡正反面(个体工商执照)、对公开户证明(有限公司需提供)</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="店铺资质：" prop="dianpuzizhi">
            <el-upload
              :disabled="dialogType == 'edit' && queryType==2"
              class="upload-demo"
              action="#"
              ref="upload"
              accept="image/*"
              :on-preview="handlePreview"
              :on-remove="(file, fileList)=>{return handleRemove(file, fileList,'dianpuzizhi')}"
              multiple
              list-type="picture-card"
              :file-list="fileList.dianpuzizhi"
              :http-request="(file)=>{return handleFileUpload(file,'dianpuzizhi')}"
              >
              <i class="el-icon-upload"></i>
              <div slot="tip" class="el-upload__tip">入住基本页截图、入住执照预览页截图、订单列表页截图</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="门店资料：" prop="lincese">
            <el-upload
              :disabled="dialogType == 'edit' && queryType==2"
              class="upload-demo"
              action="#"
              ref="upload"
              accept="image/*"
              :on-preview="handlePreview"
              :on-remove="(file, fileList)=>{return handleRemove(file, fileList,'lincese')}"
              multiple
              list-type="picture-card"
              :file-list="fileList.lincese"
              :http-request="(file)=>{return handleFileUpload(file,'lincese')}"
              >
              <i class="el-icon-upload"></i>
              <div slot="tip" class="el-upload__tip">门头照、店内照1、店内照2</div>
            </el-upload>
          </el-form-item>
        </el-form>

        <el-form v-if="dialogType == 'bindAgent'" :model="form" :rules="rules" :label-width="formLabelWidth" ref="form" >
          <el-form-item label="业务员ID" prop="agentid">
            <el-input v-model="form.agentid" style="width: 90%"></el-input>
          </el-form-item>
        </el-form>
        
        <div slot="footer" class="dialog-footer center">
          <el-button @click="dialogFormClose('form')">取 消</el-button>
          <el-button type="primary" @click="dialogFormSubmit('form')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  import { regionData } from "element-china-area-data";
  
  export default({
    name: "businessManage",
    data() {
      return {
        query: {
          isshenhe: '',
          storename: '',
          storeid: '',
          mobile: '',
          type_limit: 0,
        },
        queryType: '',
        isshenheOptions: [{
          value: '0',
          label: '已提交'
        }, {
          value: '1',
          label: '待签约'
        },{
          value: '2',
          label: '已审核'
        }, {
          value: '3',
          label: '已拒绝'
        }],
        storeOptions: [],
        tableData: [],
        total: 100,
        loading: false,
        title: '设置余额',
        dialogType: 'add',
        dialogFormVisible: false,
        form: {
          storename: '',
          account_id: '',
          logo: [],
          mobile: '',
          area: '',
          faren_mobile: '',
          provinceid: '',
          cityid: '',
          areaid: '',
          withdraw_code: '',
          is_level: '',
          
          storeid: '',
          agentid: '',
          tx_code: '',
          rate: '',
          agent_rate: '',
          tgy_rate: '',
          yyzz_name: '',
          card_url: [],
          dianpuzizhi: [],
          lincese: [],
        },
        rules: {
          storename: [
            { required: true, message: '请输入商家标题', trigger: 'blur' }
          ],
          account_id: [
            { required: true, message: '请输入抖音商户ID', trigger: 'blur' }
          ],
          // logo: [
          //   { required: true, message: '请上传LOGO', trigger: 'blur' }
          // ],
          mobile: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: "请输入正确的手机号码",
            }
          ],
          faren_mobile: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: "请输入正确的手机号码",
            }
          ],
          // withdraw_code: [
          //   { required: true, message: '请输入提现银行卡号', trigger: 'blur' }
          // ],
          tx_code: [
            { required: true, message: '请输入商家原提现银行卡开户行', trigger: 'blur' }
          ],
          status: [
            { required: true, message: '请选择状态', trigger: 'blur' }
          ],
          rate: [
            { required: true, message: '请输入商家抽佣比例', trigger: 'blur' }
          ],
          yyzz_name: [
            { required: true, message: '请输入营业执照名称', trigger: 'blur' }
          ],
          // agent_rate: [
          //   { required: true, message: '请输入合伙人佣金比例', trigger: 'blur' }
          // ],
          // tgy_rate: [
          //   { required: true, message: '请输入推广员佣金比例', trigger: 'blur' }
          // ],
        },
        formLabelWidth: '180px',
        options: regionData,
        fileList: {
          logo:[],
          card_url:[],
          dianpuzizhi:[],
          lincese:[],
        },
        page: 1,
      };
    },
    computed: {
      ...mapState(["user", "setting"]),
    },
    watch: {
      '$route': {
        immediate: true,
        handler(to, from) {
          this.queryType = to.query.type
          let data = {
            ...this.query,
            isshenhe: this.queryType,
            page: this.page
          }
          this.getStoreList(data)
        }, 
      },  
    },
    created() {
    },
    mounted() {
      let user = JSON.parse(localStorage.user);
      this.token = user.token;
      
      this.getStoreOption()
      this.queryType = this.$route.query.type
      this.getStoreList({isshenhe:this.$route.query.type})
      // setTimeout(() => {
      //   this.loading = false;
      // }, 1000);
    },
    methods: {
      ...mapMutations(["setuser", "setsetting"]),
      search() {
        console.log('查询');
        let data = {
          ...this.query,
          isshenhe: this.queryType,
          page: this.page
        }
        this.getStoreList(data)
      },
      add() {
        this.title = '添加商家'
        this.dialogType= 'add'
        Object.assign(this.form, {status: 1});
        this.dialogFormVisible = true
        console.log('新增');
      },
      handleEdit(id) {
        this.title = '修改商家'
        this.dialogType= 'edit'
        this.getStoreInfo(id)
        this.dialogFormVisible = true
        console.log('编辑');
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.page = val
        this.search()
      },
      // 弹窗关闭逻辑
      dialogFormClose(formName) {
        this.$refs[formName].resetFields();           // 移除表单验证
        Object.assign(this.form, {})                  // 清除数据缓存
        this.fileList.logo.splice(0, this.fileList.logo.length)     // 移除上传组件图片数组
        this.fileList.card_url.splice(0, this.fileList.card_url.length) // 移除上传组件图片数组
        this.fileList.dianpuzizhi.splice(0, this.fileList.dianpuzizhi.length) // 移除上传组件图片数组
        this.fileList.lincese.splice(0, this.fileList.lincese.length) // 移除上传组件图片数组
        this.dialogFormVisible = false                // 关闭弹窗
      },
      // 处理图片移除操作
      handleRemove(file, fileList, type) {
        console.log('file, fileList',file, fileList, type);
        this.form[type] = this.form[type].filter(obj => obj.uid !== file.uid);
        // this.form[type] = ''
        // this.fileList[type] = []
      },
      // 处理图片预览操作
      handlePreview(file) {
        console.log(file);
      },
      // 处理文件、图片上传操作
      handleFileUpload(file,type) {
        console.log('file', file);
        console.log('type', type);
        // 使用FormData传参数和文件
        var form = new FormData();
        form.append("file", file.file);
        this.$api.upImg(form).then(res => {
            if(res.code == 'success'){
              console.log('res.data', res.data);
              // this.form[type] = res.data.show_url
              this.form[type].push({uid: file.file.uid, url:res.data.show_url})
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch((e) => {
          this.$message.error(e.message);
          this.$refs.upload.clearFiles();
        })
      },
      // 深市县三级联动
      areaChange(e) {
        console.log('e', e, this.options)
        Object.assign(this.form, {provinceid: e[0], cityid: e[1], areaid: e[2]})
      },
      // 表单提交
      dialogFormSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            if (this.dialogType === 'add') {
              this.form.agent_rate==''?this.form.agent_rate==0:''
              this.form.tgy_rate==''?this.form.tgy_rate==0:''
              let logo = this.form.logo[0]?.url
              let card_url = this.form.card_url?.map(obj => obj.url)
              let dianpuzizhi = this.form.dianpuzizhi?.map(obj => obj.url)
              let lincese = this.form.lincese?.map(obj => obj.url)
              this.$api.addStore({...this.form,logo,card_url,dianpuzizhi,lincese}).then(
                res => {
                  if(res.code == 'success'){
                    this.$message.success(res.msg)
                    this.search()
                    this.dialogFormVisible = false
                  }else{
                    this.$message.error(res.msg)
                  }
                  this.loading = false
                }
              ).catch(err=>{
                this.$message.error(err.msg)
                this.loading = false
              })
            }
            else if (this.dialogType === 'edit') {
              let logo = this.form.logo[0]?.url
              let card_url = this.form.card_url?.map(obj => obj.url)
              let dianpuzizhi = this.form.dianpuzizhi?.map(obj => obj.url)
              let lincese = this.form.lincese?.map(obj => obj.url)
              this.$api.editStore({...this.form,logo,card_url,dianpuzizhi,lincese}).then(
                res => {
                  if(res.code == 'success'){
                    this.$message.success(res.msg)
                    this.search()
                    this.dialogFormVisible = false
                  }else{
                    this.$message.error(res.msg)
                  }
                  this.loading = false
                }
              ).catch(err=>{
                this.$message.error(err.msg)
                this.loading = false
              })
            }
            else if (this.dialogType === 'bindAgent') {
              this.$api.setAgent({...this.form}).then(
                res => {
                  if(res.code == 'success'){
                    this.$message.success(res.msg)
                    this.search()
                  }else{
                    this.$message.error(res.msg)
                  }
                  this.loading = false
                }
              ).catch(err=>{
                this.$message.error(err.msg)
                this.loading = false
              })
              this.dialogFormVisible = false
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 商家列表
      getStoreOption() {
        this.loading = true
        // 0：有分页，1：无分页数据
        this.$api.getStoreList({type_limit:1}).then(
          res => {
            if(res.code == 'success'){
              this.storeOptions = res.data
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
      // 获取商家列表
      getStoreList(data) {
        this.loading = true
        // 合伙人参数
        this.$api.getStoreList({...data}).then(
          res => {
            if(res.code == 'success'){
              this.tableData = res.data
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
      // 获取商家详情
      getStoreInfo(storeid) {
        this.$api.getStoreInfo({storeid}).then(
          res => {
            if(res.code == 'success'){
              this.form = Object.assign({storeid: res.data.id}, res.data)
              this.form.area = [String(res.data.provinceid),String(res.data.cityid),String(res.data.areaid)]
              this.fileList.logo.push({ url: res.data.logo })
              res.data.card_url.map(item => {
                item.url=item.path
                this.fileList.card_url.push(item)
              })
              res.data.dianpuzizhi.map(item => {
                item.url=item.path
                this.fileList.dianpuzizhi.push(item)
              })
              res.data.lincese.map(item => {
                item.url=item.path
                this.fileList.lincese.push(item)
              })
              this.form.logo = this.fileList.logo
              this.form.card_url = this.fileList.card_url
              this.form.dianpuzizhi = this.fileList.dianpuzizhi
              this.form.lincese = this.fileList.lincese
              // this.fileList.card_url.push({ url: res.data.logo })
              // this.fileList.dianpuzizhi.push({ url: res.data.dianpuzizhi })
              // this.fileList.lincese.push({ url: res.data.lincese })
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        }) 
      },
      // 绑定业务员(设置代理)
      bindAgent(storeid) {
        this.title = '绑定业务员'
        this.dialogType= 'bindAgent'
        Object.assign(this.form, {agentid:'',storeid})
        
        this.dialogFormVisible = true
      },
      // 解绑业务员(取消绑定代理)
      unbindSalesman(storeid) {
        this.$api.cancelAgent({storeid}).then(
          res => {
            if(res.code == 'success'){
              this.$message.success(res.msg)
              this.search()
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
    },
  });
  </script>
  
  <style scoped lang="less">
  @import "../assets/less/easyElement.less";
  // .el-tag{
  //   white-space: normal;
  //   height:auto;
  // }
  </style>
  